.month-picker-overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.9);
    z-index: 800;

    &__picker {
        background-color: #822f6e;
        padding: 20px;
        border-radius: 5px;
        box-shadow: 0 0 5px #000;

        &__heading {
            color: #f7f6f6;
            font-size: 20px;
            background-color: rgba(0, 0, 0, 0);
            outline: none;
            border: none;
            width: 100%;
            font-weight: 500;
            cursor: pointer;
            margin: 0;
            text-align: center;
        }
    }

    &__save-button {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 10px;
        padding: 6px 12px;
        outline: none;
        border: none;
        border-radius: 3px;
        color: #c0c0c0;
        cursor: pointer;
        background-color: #701e5c;
        width: calc(50% - 5px);
        margin-left: 5px;

        div {
            margin-right: 10px;
        }
    }

    &__cancel-button {
        width: calc(50% - 5px);
        margin-right: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 10px;
        padding: 6px 12px;
        outline: none;
        border: none;
        border-radius: 3px;
        color: #c0c0c0;
        background-color: #701e5c;
        cursor: pointer;

        div {
            margin-left: 10px;
        }
    }

    &__buttons-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 10px;
    }
}
