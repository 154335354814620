.month-picker {
    &__header {
        display: flex;
        align-items: center;
        margin: 15px 0 10px 0;

        h6 {
            flex-grow: 1;
            margin: 0;
            text-align: center;
            font-size: 18px;
            color: #c0c0c0;
            padding: 6px 12px;
            border-radius: 3px;
            background-color: #701e5c;
        }

        button {
            font-size: 22px;
            display: flex;
            align-items: center;
            justify-content: center;
            outline: none;
            border: none;
            background-color: rgba(0, 0, 0, 0);
            cursor: pointer;
            padding: 10px 20px;
        }
    }

    &__calendar {
        display: grid;
        grid-template-columns: auto auto auto;
        grid-gap: 10px;
        background-color: #701e5c;
        border-radius: 3px;
        padding: 10px;

        button {
            flex-grow: 1;
            margin: 0;
            text-align: center;
            font-size: 18px;
            color: #c0c0c0;
            padding: 6px 12px;
            border-radius: 3px;
            outline: none;
            background-color: rgba(0, 0, 0, 0);
            cursor: pointer;
            box-sizing: border-box;
            border: 1px solid rgba(0, 0, 0, 0);
        }

        button:disabled {
            color: #757575;
        }

        button.calendar-btn-selected {
            border: 1px solid #757575;
        }
    }
}
