.meter-set {
    display: flex;

    @media (max-width: 800px) {
        width: 100%;
    }

    &__meter {
        margin: 10px;
        width: 144px;
        height: 144px;

        @media (max-width: 800px) {
            width: auto;
            height: auto;
            flex: 1;
        }

        &__text {
            text-align: center;
            font-size: 0.95rem;
            font-weight: bold;

            @media (max-width: 800px) {
                font-size: 0.75rem;
            }

            &--orange {
                color: #ffa400;
            }

            &--turquoise {
                color: #25d6e3;
            }

            &--white {
                color: #ffffff;
            }
        }
    }
}
