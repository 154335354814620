.home-page-container {
    display: flex;
}

.home-page {
    padding: 40px 40px;
    display: flex;
    background-color: #701e5c;
    min-height: 100vh;
    box-sizing: border-box;
    height: 100vh;
    width: 100%;

    @media (max-width: 800px) {
        padding: 4rem 0 0 0;
        flex-direction: column;
        overflow-y: scroll;
    }

    &__left-col {
        padding: 15px;
        box-sizing: border-box;
        height: 100%;

        @media (max-width: 800px) {
            height: auto;
            padding: 10px;
        }

        &__tabs {
            margin-top: 26px;
            height: calc(100% - 190px);

            @media (max-width: 800px) {
                display: none;
            }
        }
    }

    &__right-col {
        flex-grow: 1;
        padding: 15px;
        box-sizing: border-box;
        height: 100%;

        @media (max-width: 800px) {
            height: auto;
        }
    }
}
