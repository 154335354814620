.entity-tab-list {
    height: 100%;
    border-radius: 6px;
    overflow-y: hidden;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;

    &__header {
        display: flex;
        justify-content: space-between;
        padding: 15px 25px 15px 15px;
        box-shadow: 0 0 10px -3px gray;
        background-color: #ffffff;
        position: relative;

        &__tabs {
            display: flex;
            background-color: #e6e6e6;
            border-radius: 6px;
            box-shadow: 0 0 4px -3px gray inset;
        }

        &__title {
            font-weight: bold;
            font-size: 20px;
            margin: 0;
            padding: 5px 10px;
            cursor: pointer;
            border-radius: 6px;
            opacity: 0.7;

            &--active {
                background-color: #e6ccdf;
                position: relative;
                cursor: default;
                opacity: 1;

                &:after {
                    content: '';
                    position: absolute;
                    top: 100%;
                    left: calc(50% - 8px);
                    width: 0;
                    height: 0;
                    border-left: 8px solid transparent;
                    border-right: 8px solid transparent;
                    border-top: 8px solid #e6ccdf;
                }
            }

            &__icon {
                vertical-align: middle;
                color: #a6a6a6;
                margin-left: 3px;
                font-size: 16px;
            }
        }

        &__button {
            background-color: rgba(0, 0, 0, 0);
            border: none;
            outline: none;
            cursor: pointer;
            font-size: 16px;

            &:hover {
                color: #8e3682;
            }
        }
    }

    &__list {
        list-style-type: none;
        padding: 0;
        margin: 0;
        height: calc(100% - 64px);
        overflow: auto;
        background-color: #ffffff;
        flex-grow: 1;
    }

    &__footer-note {
        padding: 12px;
        text-align: center;
        font-size: 14px;
        color: #606060;
        background-color: #ffffff;
    }
}
