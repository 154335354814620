.settings-page-container {
    display: flex;
}

.settings-page {
    padding: 40px 40px;
    background-color: #701e5c;
    min-height: 100vh;
    box-sizing: border-box;
    height: 100vh;
    width: 100%;

    @media (max-width: 800px) {
        padding: 4rem 0 0 0;
        flex-direction: column;
        max-height: 100vh;
    }

    input[type='number']::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        margin: 0;
    }

    &__header {
        width: 100%;
        margin-bottom: 40px;
        padding-right: 8px;
        box-sizing: border-box;

        @media (max-width: 800px) {
            margin-top: 2rem;
            padding-right: 0;
        }

        &__dmi {
            margin-left: auto;
            width: 250px;

            @media (max-width: 800px) {
                margin: auto;
            }

            &__label {
                color: #c0c0c0;
                font-size: 16px;
                width: 100%;
                display: block;
                margin: 0 0 6px 0;
            }
        }
    }

    &__toggles {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        @media (max-width: 800px) {
            flex-direction: column;
        }

        &__description-toggle {
            background-color: rgba(63, 67, 66, 0.5);
            padding: 20px;
            margin: 8px;
            width: calc(33% - 16px);
            border-radius: 5px;
            box-sizing: border-box;
            box-shadow: 0 0 6px -3px black inset;

            @media (max-width: 800px) {
                padding: 20px;
                margin: 8px 20px;
                width: calc(100% - 40px);
                border-radius: 5px;
                box-sizing: border-box;
            }

            &__title {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 5px;

                h6 {
                    margin: 0;
                    color: #c0c0c0;
                    font-size: 16px;
                }
            }

            p {
                color: #c0c0c0;
                font-size: 14px;
                margin: 20px 0 0 0;
            }
        }
    }

    &__box-input {
        color: #c0c0c0;
        outline: none;
        border: none;
        width: 100%;
        border-radius: 3px;
        cursor: pointer;
        padding: 12px 0;
        font-size: 18px;
        background-color: #822f6e;
        text-align: center;
    }

    .react-toggle {
        .react-toggle-track {
            width: 36px;
            height: 16px;
            background-color: #e0e0e0 !important;
        }

        .react-toggle-thumb {
            top: -2px;
            left: -1px;
            height: 20px;
            width: 20px;
            border: none;
            outline: none;
            background-color: #ffffff;
            box-shadow: 0 0 8px -3px black !important;
        }

        &--checked .react-toggle-thumb {
            left: 18px;
            background-color: #bf81b0;
        }

        &--checked .react-toggle-track {
            background-color: #e3c2db !important;
        }
    }
}
