.navbar {
    display: none;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100vw;
    background-color: #822f6e;
    box-shadow: 0 0 2px #000;
    z-index: 650;
    padding: 1rem;
    box-sizing: border-box;

    @media (max-width: 800px) {
        display: flex;
    }

    &__month {
        color: #d9d9d9;
        font-size: 20px;
        background-color: rgba(0, 0, 0, 0);
        outline: none;
        border: none;
        font-weight: 500;
        cursor: pointer;
        text-align: right;
        padding: 0;
    }
}

.drawer-overlay {
    display: none;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgb(0, 0, 0);
    z-index: 699;
    pointer-events: none;

    @media (max-width: 800px) {
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 0;
        transition: right 0.3s ease;
    }

    &--expanded {
        @media (max-width: 800px) {
            opacity: 0.9;
            pointer-events: all;
        }
    }
}

.drawer {
    background-color: #822f6e;
    min-width: 240px;
    width: 240px;
    height: 100vh;
    box-shadow: -6px 0 10px #000;
    z-index: 700;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media (max-width: 800px) {
        min-width: 80%;
        width: 80%;
        position: fixed;
        top: 0;
        bottom: 0;
        right: 100%;
        transition: right 0.3s ease;
    }

    &--expanded {
        @media (max-width: 800px) {
            right: 20%;
            box-shadow: -3px 0 10px #000;
        }
    }

    input[type='number']::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        margin: 0;
    }

    &__month {
        color: #f7f6f6;
        font-size: 20px;
        background-color: rgba(0, 0, 0, 0);
        outline: none;
        border: none;
        width: 100%;
        padding: 15px 20px;
        font-weight: 500;
        cursor: pointer;
        margin-top: 5px;
    }

    &__box-button {
        color: #c0c0c0;
        outline: none;
        border: none;
        width: calc(100% - 40px);
        border-radius: 3px;
        cursor: pointer;
        padding: 12px 0;
        font-size: 18px;
        background-color: #701e5c;
        margin: 5px 20px;
        text-align: center;
    }

    &__icon-button {
        display: flex;
        background-color: rgba(0, 0, 0, 0);
        outline: none;
        border: none;
        width: 100%;
        cursor: pointer;
        padding: 12px 20px;
        font-size: 16px;
        color: #f7f6f6;
        justify-content: left;
        align-items: center;
        font-weight: 500;
        position: relative;

        span {
            background-color: #701e5c;
            width: 5px;
            height: 0;
            position: absolute;
            right: 0;
            transition: height 0.5s ease;
        }

        &:hover > span {
            height: 100%;
        }

        &--active {
            span {
                height: 100% !important;
            }
        }

        div {
            margin: 0 14px;
        }
    }

    &__hr {
        margin: 2px 10px;
        border: none;
        border-top: 1px solid #701e5c;
    }
}
