.list-item {
    display: flex;
    padding: 10px 15px;
    margin: 0 25px;
    border-bottom: 1px solid #e6e6e6;
    cursor: pointer;

    @media (max-width: 800px) {
        margin: 0 15px;
    }

    &__content {
        width: 60%;
        text-align: left;

        div {
            font-size: 18px;
        }

        span {
            font-size: 14px;
            color: #606060;
        }
    }

    &__amount {
        flex-grow: 1;
        text-align: right;
        font-size: 18px;
    }
}
