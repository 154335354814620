.signup-form {
    width: 300px;
    margin: auto;

    &__label {
        display: block;
        color: #c0c0c0;
        margin: 20px 0 8px 0;
    }

    &__label-link {
        display: block;
        color: #c0c0c0;
        margin: 8px 0;
        font-size: 14px;
        text-align: center;
        opacity: 0.75;

        &:hover {
            opacity: 1;
        }
    }

    &__input {
        width: 100%;
        box-sizing: border-box;
        display: block;
        color: #c0c0c0;
        background-color: #8e3682;
        border: none;
        padding: 10px;
        line-height: 20px;
        font-size: 20px;
        outline: none;
        text-align: center;
    }

    &__button {
        width: 100%;
        box-sizing: border-box;
        display: block;
        color: #c0c0c0;
        background-color: #8e3682;
        border: none;
        padding: 10px;
        line-height: 20px;
        font-size: 18px;
        outline: none;
        text-align: center;
        margin-top: 45px;
        cursor: pointer;
    }

    &__hr {
        border: 1px solid #822f6e;
        margin: 40px 20px;

        @media (max-width: 800px) {
            margin: 30px 20px;
        }
    }

    &__google {
        display: flex;
        justify-content: center;
    }
}
