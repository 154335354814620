.overlay-loader {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.9);
    z-index: 900;

    &__loader {
        background-color: #ffffff;
        border-radius: 6px;
        padding: 30px;
        height: 180px;
        width: 180px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &__text {
            margin-top: auto;
        }

        &__icon {
            margin-top: 13px;
            margin-right: 2px;
        }
    }
}
