@import '~animate.css/animate.css';

.auth-page {
    display: flex;
    background-color: #701e5c;
    min-height: 100vh;
    box-sizing: border-box;
    height: 100vh;

    @media (max-width: 800px) {
        min-height: 100vh;
        height: auto;
    }

    &__left-col {
        background-color: #5c0f49;
        position: relative;
        width: calc(50% - 100px);

        @media (max-width: 800px) {
            display: none;
        }

        &:before {
            content: '';
            background-color: #5c0f49;
            position: absolute;
            left: calc(100% - 100px);
            top: 0;
            height: 100%;
            width: 200px;
            transform: skewX(-10deg);
        }

        &__box {
            width: 70%;
            height: 100%;
            margin: 0 calc(15% - 30px) 0 calc(15% + 30px);
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;

            h6 {
                color: #ffffff;
                font-size: 5vw;
                margin: 0;

                span {
                    background-color: #4f4f4f;
                    position: relative;
                    display: inline-block;

                    &:before {
                        content: '';
                        background-color: #4f4f4f;
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        width: 0.75vw;
                        left: -0.75vw;
                    }

                    &:after {
                        content: '';
                        background-color: #4f4f4f;
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        width: 0.75vw;
                        right: -0.75vw;
                    }
                }
            }
        }
    }

    &__right-col {
        background-color: #701e5c;
        width: calc(50% + 100px);
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 40px 0 40px 130px;
        box-sizing: border-box;

        @media (max-width: 800px) {
            width: calc(100% + 100px);
            padding: 20px 10px;
        }

        &__heading {
            display: flex;
            margin-bottom: 20px;

            @media (max-width: 800px) {
                margin-bottom: 0;
                margin-top: 20px;
            }

            h2 {
                color: #a07495;
            }

            &__action {
                cursor: pointer;

                &--active {
                    color: #ffffff !important;
                }
            }
        }

        &__header {
            background-color: #5c0f49;
            position: relative;
            width: 100%;
            display: none;
            padding: 20px;
            margin: 60px 0 20px 0;
            box-sizing: border-box;
            transform: skewY(-10deg);

            @media (max-width: 800px) {
                display: block;

                &:before {
                    content: '';
                    display: block;
                    background-color: #5c0f49;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    right: 0;
                    left: 0;
                    transform: skewY(20deg);
                    z-index: 0;
                }
            }

            &__box {
                width: 70%;
                height: 100%;
                margin: auto;
                display: flex;
                justify-content: center;
                align-items: center;
                transform: skewY(10deg);

                h6 {
                    color: #ffffff;
                    font-size: 5vw;
                    margin: 0;
                    text-align: center;

                    @media (max-width: 800px) {
                        font-size: 1.4rem;
                        z-index: 1;
                    }

                    span {
                        background-color: #4f4f4f;
                        position: relative;
                        display: inline-block;

                        &:before {
                            content: '';
                            background-color: #4f4f4f;
                            position: absolute;
                            top: 0;
                            bottom: 0;
                            width: 0.75vw;
                            left: -0.75vw;
                        }

                        &:after {
                            content: '';
                            background-color: #4f4f4f;
                            position: absolute;
                            top: 0;
                            bottom: 0;
                            width: 0.75vw;
                            right: -0.75vw;
                        }
                    }
                }
            }
        }
    }

    &__center {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        pointer-events: none;

        @media (max-width: 800px) {
            bottom: unset;
            top: 20px;
        }

        img {
            width: 5vw;
            filter: drop-shadow(0 0 2px #000);
            animation: bounceIn;
            animation-duration: 1s;

            @media (max-width: 800px) {
                width: 10vw;
            }
        }
    }
}
