.splash-loader {
    display: flex;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    justify-content: center;
    align-items: center;
    background-color: #701e5c;
    z-index: 1000;

    img {
        max-height: 35vh;
        min-height: 200px;
        z-index: 11;
    }

    &__wave {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 0;
        height: 0;
        margin: auto;
        border-radius: 100%;
        border: 1px solid #ce7ec3;
        animation: wave-out 3s infinite;
        animation-timing-function: ease-out;
        opacity: 1;

        &--inner {
            animation-delay: 1.5s;
        }
    }

    &__sea {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 10;
    }
}

@keyframes wave-out {
    from {
        width: 0;
        height: 0;
        opacity: 1;
    }
    to {
        width: 1000px;
        height: 1000px;
        opacity: 0;
    }
}
