.entity-list {
    height: 100%;
    border-radius: 6px;
    overflow: hidden;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;

    &__header {
        display: flex;
        justify-content: space-between;
        padding: 20px 25px;
        box-shadow: 0 0 10px -3px gray;
        background-color: #ffffff;
        position: relative;

        &__title {
            font-weight: bold;
            font-size: 20px;
            margin: 0;
        }

        &__button {
            background-color: rgba(0, 0, 0, 0);
            border: none;
            outline: none;
            cursor: pointer;
            font-size: 16px;

            &:hover {
                color: #8e3682;
            }

            @media (max-width: 800px) {
                padding: 0;
            }
        }
    }

    &__list {
        list-style-type: none;
        padding: 0;
        margin: 0;
        height: calc(100% - 64px);
        overflow: auto;
        background-color: #ffffff;
        flex-grow: 1;

        @media (max-width: 800px) {
            max-height: 60vh;
        }
    }

    &__footer-note {
        padding: 12px;
        text-align: center;
        font-size: 14px;
        color: #606060;
        background-color: #ffffff;

        &__total {
            display: flex;
            box-sizing: border-box;
            width: 30%;
            padding: 10px 15px;
            margin: 10px 15px 10px auto;
            border-top: 1px solid #e6e6e6;
            border-bottom: 1px solid #e6e6e6;

            @media (max-width: 800px) {
                margin: 10px 5px;
                width: calc(100% - 5px);
            }
        }

        &__total-amount {
            flex-grow: 1;
            text-align: right;
            font-size: 18px;
            color: #000000;
        }

        &__total-title {
            flex-grow: 1;
            text-align: left;
            font-size: 18px;
            color: #000000;
        }
    }
}
